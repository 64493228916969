/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Link } from "gatsby";
import { auth, useAuth } from "gatsby-theme-firebase";
import { Gallery } from "gatsby-theme-gallery";
import Button from "gatsby-theme-firebase/src/components/Button";
import LoginModal from "../components/LoginModal";
import { Code, CodeBlock, Header, A } from "../components/Styles";
import { Layout } from "../components";

import ApplicationViewContainer from '../sections/application/ApplicationViewContainer';
import LoginSignupViewContainer from '../sections/login+signup/LoginSignupViewContainer'

const SDGs = () => {
  const [toggleLogin, setToggleLogin] = React.useState(false);
  const { isLoading, isLoggedIn, profile } = useAuth();

  if (isLoading) {
    return null;
  }

  return (
        <Layout>
        <>
          {isLoggedIn ? (
            <div>
              <ApplicationViewContainer />
            </div>
            ) : (
            <div>
              <LoginSignupViewContainer />
            </div>
          )}

          {toggleLogin && <LoginModal setToggleLogin={setToggleLogin} />}
        </>
        </Layout>
  );
};

export default SDGs;
