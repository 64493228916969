import React, { useContext, useEffect, useRef, useState } from "react";
import { useDocument } from 'react-firebase-hooks/firestore';
import { compose } from "recompose";
import styled from 'styled-components';
import { accessIfRole } from '../../utils/siteAuth';
import SiteContext, { connectSiteContext } from "../../utils/siteContext";
import { withPageWrapper } from "../../components/shared/PageWrapper/PageWrapperComponent";
import { auth } from 'gatsby-theme-firebase';
import { firestore, useFirestoreDoc } from 'gatsby-theme-firebase';

import ApplicationViewComponent from './ApplicationViewComponent';

// Fetches a hacker's application from Firestore.
// Returns a firebase.firestore.DocumentReference of the current user's
// application entry in the /applications Firestore collection.
const fetchApplication = firebase =>
  () => firebase.firestore.collection('users').doc(firebase.auth.currentUser && firebase.auth.currentUser.uid);


const createApplication = firebase =>
  () => firebase.firestore.collection('users').doc(firebase.auth.currentUser && firebase.auth.currentUser.uid).update({ application: { submitted: false }});


// Updates a hacker's application with the new information they've provided.
const updateApplication = firebase => updatedAppInfo => {

  if(firebase.auth.currentUser && !updatedAppInfo.submitted)
    firebase.firestore.collection('users').doc(firebase.auth.currentUser.uid)
      .update({ application: updatedAppInfo});
}

// Submit a hacker's application. Non reversible.
const submitApplication = firebase => updatedAppInfo => {
  if(firebase.auth.currentUser)
    firebase.firestore.collection('users').doc(firebase.auth.currentUser.uid)
      .update({ application: { ...updatedAppInfo, submitted: true, submittedAt: firebase.getTimestamp(new Date()) } })
}


const mapContextStateToProps = ({ state: { firebase } }) => ({
  fetchAppFirestore: fetchApplication(firebase),
  createAppFirestore: createApplication(firebase),
  updateAppFirestore: updateApplication(firebase),
  submitAppFirestore: submitApplication(firebase),
});

const enhance = compose(
  withPageWrapper({ title: "My Application" }),
  connectSiteContext(mapContextStateToProps),
);



const ApplicationViewContainer = ({
  fetchAppFirestore,
  createAppFirestore,
  updateAppFirestore,
  submitAppFirestore,
}) => {

  let curUser = auth.currentUser;

  const [data, isLoading, error] = useFirestoreDoc(
    firestore.collection("users").doc(curUser && curUser.uid),
  );


  const [ appState, updateAppState ] = useState("FETCHING");
  const [ localAppInfo, updateLocalAppInfo ] = useState({ submitted: false });
  const { dispatch } = useContext(SiteContext);

  const appRef = useRef();

  appRef.current = localAppInfo;
  // save to firestore before component unmounts or page unloads
  useEffect(() => {
    window.addEventListener('beforeunload', () => updateAppFirestore(appRef.current));
    dispatch({ type: "UPDATE_TITLE", data: { title: "My Application" }});

    return () => {
      window.removeEventListener('beforeunload', () => updateAppFirestore(appRef.current))
      updateAppFirestore(appRef.current);
    }
  }, [])


  if (isLoading)
    return null;

  if (error)
    console.log(error);

  let value;

  if (data !== null) {
    value = data.application;
  } else {
    return null;
  }

  const updateAppInfo = newAppInfo => {
    const newLocalAppInfo = {
      ...localAppInfo,
      ...newAppInfo,
    };

    updateLocalAppInfo(newLocalAppInfo);
    if(!newLocalAppInfo.submitted) dispatch({ type: "UPDATE_DASHBOARD_TOAST", data: { toastName: "appModified" } });
  }

  const submitAppInfo = () => {
//    createAppFirestore();
    submitAppFirestore(appRef.current);
    updateAppState("SUBMITTING");
    dispatch({ type: "UPDATE_DASHBOARD_TOAST", data: { toastName: "appSubmitted" } });
  }

  if(value) {
    if(value && ((appState === "FETCHING") || (value.submitted && appState === "SUBMITTING"))) {
      updateAppInfo(value);
      updateAppState("FETCHED");
      if(value.submitted) {
        updateAppState("SUBMITTED");
        dispatch({ type: "UPDATE_DASHBOARD_TOAST", data: { toastName: "appSubmitted" } });
      }
    } else if(!value && (appState === "FETCHING")) {
      createAppFirestore();
      updateAppState("FETCHED");
    }
  }

  return (
      <ApplicationViewComponent curUser={curUser} curUserName={curUser && curUser.displayName} updateAppInfo={updateAppInfo} submitAppInfo={submitAppInfo} appState={appState} curAppInfo={localAppInfo} />
  );
};


export default enhance(ApplicationViewContainer);
