import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { debounce } from "debounce";
import { mediaSize, universityListOptions } from "../../utils/siteTools";

import LoadingSpinner from "../../static/img/loaders/default_color_secondary.svg";
import TextInput from "../../components/shared/TextInput/TextInputComponent";
import TextArea from "../../components/shared/TextArea/TextAreaComponent";
import SelectDropdown from "../../components/shared/SelectDropdown/SelectDropdownComponent";
import Button from "../../components/shared/Button/ButtonComponent";
import Text from "../../components/shared/Text/TextComponent";
import Heading from "../../components/shared/Heading/HeadingComponent";
import Modal from "../../components/shared/Modal/ModalComponent";

// The template for an application info, including options, etc.
const appTemplate = [
  {
    title: "",
    content: [
      {
        label: "What is your Name (full name)?",
        type: "text",
        question: {
          id: "userName",
          type: "text",
          placeholder: "Mr DuGut™"
        }
      },
      {
        label: "What is your E-mail?",
        type: "text",
        question: {
          id: "userEmail",
          type: "text",
          placeholder: "hello@dugut.app"
        }
      },
      {
        label: "Do you believe the world is perfect?",
        type: "select",
        question: {
          id: "perfectWorld",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you spend time to make the world a better place for your future?",
        type: "select",
        question: {
          id: "betterWorld",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "What do you care about:",
        type: "select",
        question: {
          id: "whatDoYouCareAbout",
          type: "option",
          placeholder: "Choose one or more from the below:",
          options: [
            { label: "Health, Wellbeing and Demographic", value: "health" },
            { label: "Education, Gender and Inequality", value: "education" },
            { label: "Energy, Decarbonization and Sustainable Industry", value: "energy" },
            { label: "Sustainable Cities, and Communities", value: "sustainablecities" },
            { label: "Sustainable Food, Water and Oceans", value: "sustainablefood" },
          ],
          componentOptions: {
            allowCreate: false,
            allowMultiple: true
          }
        }
      },
      {
        label: "What is the SDG most associated with this issue?",
        type: "select",
        question: {
          id: "sdgAssociated",
          type: "option",
          placeholder: "Type to search or select from the below:",
          options: [
            { label: "No Poverty", value: "noPoverty" },
            { label: "Zero Hunger", value: "zeroHunger" },
            { label: "Good Health & Well-Being", value: "goodHealth" },
            { label: "Quality Education", value: "qualityEducation" },
            { label: "Gender Equality", value: "genderEquality" },
            { label: "Clean Water & Sanitation", value: "cleanWater" },
            { label: "Affordable & Clean Energy", value: "affordableEnergy" },
            { label: "Decent Work & Economic Growth", value: "decentWork" },
            { label: "Industry, Innovation, & Infrastructure", value: "industryInnovation" },
            { label: "Reduced Inequalities", value: "reducedInequalities" },
            { label: "Sustainable Cities & Communities", value: "sustainableCities" },
            { label: "Responsible Consumption & Production", value: "responsibleConsumption" },
            { label: "Climate Action", value: "climateAction" },
            { label: "Life Below Water", value: "lifeBelowWater" },
            { label: "Life On Land", value: "lifeOnLand" },
            { label: "Peace, Justice, and Strong Institutions", value: "peaceJustice" },
            { label: "Partnerships", value: "partnerships" }

          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
    ]
  },
  {
    title: "Tell us a bit about your opinion on SDGs and Brands.",
    content: [
      {
        label: "Would you switch brands if they offered you rewards that you can donate to Social Organizations behind your SDG?",
        type: "select",
        question: {
          id: "switchBrands",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you choose a brand again if they support your SDG?",
        type: "select",
        question: {
          id: "brandChoice",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you engage brands to encourage them to take action, and change for the betterment of the world?",
        type: "select",
        question: {
          id: "brandEngagement",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you collaborate with a brand to help solve issues you care about?",
        type: "select",
        question: {
          id: "brandCollaboration",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you change your consumer habit if the opportunities were there?",
        type: "select",
        question: {
          id: "consumberHabits",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you use this app regularly if it would help to solve issues you care about?",
        type: "select",
        question: {
          id: "regularUsage",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you share your efforts with your social media friends?",
        type: "select",
        question: {
          id: "sharingEfforts",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "If you could see how your lifestyle helps to change your world, would you be stimulated to do more?",
        type: "select",
        question: {
          id: "lifestyleChoices",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      },
      {
        label: "Would you share your data if it would help make your world a better place?",
        type: "select",
        question: {
          id: "dataSharing",
          type: "option",
          placeholder: "Yes / No",
          options: [
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ],
          componentOptions: {
            allowCreate: false
          }
        }
      }
    ]
  }
];

const Container = styled.div`
  width: 50vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaSize.tablet`
    width: 100%;
  `}
`;

const QuestionSet = styled.div`
  margin-bottom: 1em;

  &:first-of-type {
    margin-top: 0;
  }
`;

const QuestionTitle = styled(Heading)`
  margin: 0.5em 0;
`;

const Question = styled.div`
  margin-bottom: 1em;
`;

const QuestionLabel = styled(Text)`
  margin-bottom: 10px;
`;

const Loading = styled.div`
  width: 100%;
  height: 10vh;
  margin: 50vh 0;

  // prevent from collapsing when there's no content during loading
  &:empty:after {
    content: "&nbsp;";
    visibility: hidden;
  }

  background: center / contain no-repeat url(${LoadingSpinner});
  opacity: 0.75;
`;

const Submit = styled.div`
  height: 4em;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > p {
    margin: auto 0;

    ${mediaSize.phone`
      margin-top: 0.5em;
    `}
  }

  ${mediaSize.phone`
    height: auto;
    flex-direction: column;
  `}
`;

const SubmitButton = styled(Button)`
  width: 10em;
  margin: auto 1em auto 0;

  display: inline-block;

  // prevent button from collapsing when there's no content during loading
  &:empty:after {
    content: "&nbsp;";
    visibility: hidden;
  }

  &:disabled {
    cursor: auto;
    background-color: ${props => props.theme.colors.grey};
    opacity: 0.75;
  }

  ${mediaSize.phone`
    width: 100%;
    margin: auto 0;
  `}
`;

const ModalFrame = styled.div`
  display: ${props => (props.show ? "flex" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(88, 88, 88, 0.49);
`;

const ConfirmSubmitModal = styled(Modal)`
  width: 40em;
  height: 20em;
  padding: 5em;

  border-radius: ${props => props.theme.app.border.radius};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    margin: auto;
    text-align: center;
  }

  ${mediaSize.phone`
    width: 85vw;
    height: 60vw;
    padding: 2em;
  `}
`;

const ConfirmSubmit = ({ confirmSubmit, closeModal }) => (
  <ConfirmSubmitModal
    backgroundColor="lightBlack"
    handleClickOutside={closeModal}
    onClickCloseHandler={closeModal}
  >
    <div>
      <Text size="big" color="white">
        Are you sure you want to submit?
      </Text>
      <Text size="big" color="white">
        You won&apos;t be able to edit your application after.
      </Text>
    </div>
    <SubmitButton
      label="Yes, Submit"
      backgroundColor="secondary"
      color="white"
      onClickHandler={confirmSubmit}
    />
  </ConfirmSubmitModal>
);

const renderQuestion = (
  { label, type, question },
  defaultValue,
  saveResponseHandler,
  appSubmitted
) => {
  let QuestionComponent;
  switch (type) {
    case "text":
      QuestionComponent = (
        <TextInput
          placeholder={question.placeholder}
          name={question.id}
          type={question.type}
          defaultValue={defaultValue}
          disabled={appSubmitted}
          onChangeHandler={value => saveResponseHandler(question.id, value)}
        />
      );
      break;

    case "textLong":
      QuestionComponent = (
        <TextArea
          placeholder={question.placeholder}
          name={question.id}
          type={question.type}
          defaultValue={defaultValue}
          disabled={appSubmitted}
          onChangeHandler={value => saveResponseHandler(question.id, value)}
        />
      );
      break;

    case "select":
      QuestionComponent = (
        <SelectDropdown
          placeholder={question.placeholder}
          name={question.id}
          type={question.type}
          options={question.options}
          {...question.componentOptions}
          defaultValue={defaultValue}
          disabled={appSubmitted}
          onChangeHandler={value => saveResponseHandler(question.id, value)}
        />
      );
      break;

    default:
      QuestionComponent = <div>No type found</div>;
  }

  return (
    <Question key={question.id}>
      <QuestionLabel color="grey" size="small" weight="bold">
        {label.toUpperCase()}
        {question.optional ? "" : "*"}
      </QuestionLabel>
      {QuestionComponent}
    </Question>
  );
};

const renderQuestionSet = (
  { title, content },
  curAppInfo,
  saveResponseHandler,
  appSubmitted
) => (
  <QuestionSet key={title}>
    <QuestionTitle color="black" size="small" weight="normal">
      {title}
    </QuestionTitle>
    {content.map(q =>
      renderQuestion(
        q,
        curAppInfo[q.question.id],
        saveResponseHandler,
        appSubmitted
      )
    )}
  </QuestionSet>
);

const renderSubmit = (saveState, appState, appFilled, submitHandler) => {
  if (appState === "SUBMITTED") {
    return (
      <Text color="green" size="normal">
        Your application has been submitted!
      </Text>
    );
  }

  let saveMsg;
  if (saveState === "SAVING") saveMsg = "Saving...";
  else if (saveState === "SAVED") saveMsg = "Your responses have been saved.";
  return (
    <Submit>
      {
        <SubmitButton
          label="Submit"
          backgroundColor="secondary"
          color="white"
          disabled={appState === "SUBMITTING" || !appFilled}
          onClickHandler={submitHandler}
        />
      }
      <Text color="grey" size="normal">
        {saveMsg}
      </Text>
    </Submit>
  );
};

const ApplicationViewComponent = ({
  curUserName,
  curAppInfo,
  updateAppInfo,
  submitAppInfo,
  appState
}) => {

  const [saveState, updateSaveState] = useState("READY");
  const [confirmSaveOpen, toggleConfirmSave] = useState(false);
  const [renderContent, updateRenderContent] = useState(false);

  const appSubmitted = appState === "SUBMITTED";
  const appFilledOut = appTemplate.every(qs =>
    qs.content.every(
      q =>
        (Object.keys(curAppInfo).includes(q.question.id) &&
          curAppInfo &&
          curAppInfo[q.question.id] !== "") ||
        q.question.optional
    )
  );

  const delayedUpdateField = useCallback(
    debounce((fieldId, value, appInfo) => {
      const newAppInfo = {
        ...appInfo,
        [fieldId]: value
      };

      updateAppInfo(newAppInfo);
      updateSaveState("SAVED");
    }, 1000),
    [submitAppInfo]
  );

  useEffect(() => {
    const appTimer = setTimeout(() => updateRenderContent(true), 500);

    return () => clearTimeout(appTimer);
  }, []);

  const saveResponseField = (fieldId, value) => {
    updateSaveState("SAVING");
    delayedUpdateField(fieldId, value, curAppInfo);
  };

  const closeModal = () => toggleConfirmSave(false);

  const submitHandler = () => toggleConfirmSave(true);

  const confirmSubmit = () => {
    submitAppInfo();
    closeModal();
  };

  let userName = curUserName;

  if (userName == null)
    userName = "";


  return (
    <Container>
      { renderContent ? (
        <>
          <Heading color="black" size="small" weight="normal">{`Hi ${
            userName.split(" ")[0]
          }! Let's get to know you!`}</Heading>
          {appTemplate.map(qs =>
            renderQuestionSet(qs, curAppInfo, saveResponseField, appSubmitted)
          )}
          {renderSubmit(saveState, appState, appFilledOut, submitHandler)}
        </>
      ) : (
        <Loading />
      )}

      <ModalFrame show={confirmSaveOpen}>
        <ConfirmSubmit confirmSubmit={confirmSubmit} closeModal={closeModal} />
      </ModalFrame>
    </Container>
  );
};

export default ApplicationViewComponent;
